import React from 'react';
import { CareersPageTemplate } from 'src/templates';
import { generateJobsProps } from 'src/lib/careers-utils';
import { marketingCmsClient } from 'src/lib/contentful-client';
import { ContentfulTemplates, CmsComponents } from 'src/lib/constants';
import { generateBlogExcerptProps } from 'src/lib/utils';
import { optimizeContentfulImages } from 'src/lib/image-helpers';

const CareersPage = ({ fields }) => {
  return (
    <CareersPageTemplate
      className='department-location-page'
      fields={fields}
      totalJobCount={{ current: fields.jobCount }}
      hasSearchButton
    />
  );
};

export async function getStaticProps({ preview }) {
  let jobsProps;
  try {
    jobsProps = await generateJobsProps({
      dynamicSegments: [],
    });
  } catch (e) {
    throw new Error(e);
  }

  let data = {};
  let careersPage;

  try {
    const entries = await marketingCmsClient.getEntries({
      content_type: ContentfulTemplates.CAREERS_PAGE,
      include: 10,
      'fields.pageSlug': 'careers',
    });
    careersPage = entries?.items[0];
  } catch {
    throw new Error('Careers page not found.');
  }

  try {
    const blogPostExcerptInstance = careersPage?.fields?.components.find(
      (component) => {
        return (
          component.sys?.contentType?.sys?.id ===
          CmsComponents.BLOG_POST_EXCERPT
        );
      },
    );

    let blogExcerptComponents;
    try {
      blogExcerptComponents = await generateBlogExcerptProps(
        careersPage,
        blogPostExcerptInstance,
      );
    } catch (e) {
      throw new Error(e);
    }

    data = {
      props: {
        fields: {
          ...jobsProps?.props?.fields,
          components:
            // If blog excerpt lookup fails, we fall back to just the props generated from Lever data
            blogExcerptComponents || jobsProps?.props?.fields?.components,
        },
        preview: !!preview,
      },
    };
  } catch {
    data = {
      props: {
        fields: {
          ...jobsProps?.props?.fields,
          components: jobsProps?.props?.fields?.components,
        },
        preview: !!preview,
      },
    };
  }

  return optimizeContentfulImages(data);
}

export default CareersPage;
